var canvas = document.getElementById("canvas"),
  can_w = parseInt(canvas.getAttribute("width")),
  can_h = parseInt(canvas.getAttribute("height")),
  ctx = canvas.getContext("2d");

// console.log(typeof can_w);

var ball = {
    x: 0,
    y: 0,
    vx: 0,
    vy: 0,
    r: 0,
    alpha: 1,
    phase: 0
  },
  R = 2,
  balls = [],
  alpha_f = 0.03,
  alpha_phase = 0,
  // Line
  link_line_width = 0.8,
  dis_limit = 260,
  add_mouse_point = true,
  mouse_in = false,
  mouse_ball = {
    x: 0,
    y: 0,
    vx: 0,
    vy: 0,
    r: 0,
    type: "mouse"
  };

// Random speed
function getRandomSpeed(pos) {
  var min = -1,
    max = 1;
  switch (pos) {
    case "top":
      return [randomNumFrom(min, max), randomNumFrom(0.1, max)];
      break;
    case "right":
      return [randomNumFrom(min, -0.1), randomNumFrom(min, max)];
      break;
    case "bottom":
      return [randomNumFrom(min, max), randomNumFrom(min, -0.1)];
      break;
    case "left":
      return [randomNumFrom(0.1, max), randomNumFrom(min, max)];
      break;
    default:
      return;
      break;
  }
}
function randomArrayItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}
function randomNumFrom(min, max) {
  return Math.random() * (max - min) + min;
}
// Random Ball
function getRandomBall() {
  var pos = randomArrayItem(["top", "right", "bottom", "left"]);
  switch (pos) {
    case "top":
      return {
        x: randomSidePos(can_w),
        y: -R,
        vx: getRandomSpeed("top")[0],
        vy: getRandomSpeed("top")[1],
        r: R,
        alpha: 1,
        phase: randomNumFrom(0, 10)
      };
      break;
    case "right":
      return {
        x: can_w + R,
        y: randomSidePos(can_h),
        vx: getRandomSpeed("right")[0],
        vy: getRandomSpeed("right")[1],
        r: R,
        alpha: 1,
        phase: randomNumFrom(0, 10)
      };
      break;
    case "bottom":
      return {
        x: randomSidePos(can_w),
        y: can_h + R,
        vx: getRandomSpeed("bottom")[0],
        vy: getRandomSpeed("bottom")[1],
        r: R,
        alpha: 1,
        phase: randomNumFrom(0, 10)
      };
      break;
    case "left":
      return {
        x: -R,
        y: randomSidePos(can_h),
        vx: getRandomSpeed("left")[0],
        vy: getRandomSpeed("left")[1],
        r: R,
        alpha: 1,
        phase: randomNumFrom(0, 10)
      };
      break;
  }
}
function randomSidePos(length) {
  return Math.ceil(Math.random() * length);
}

// Draw Ball
function renderBalls() {
  Array.prototype.forEach.call(balls, function(b) {
    if (!b.hasOwnProperty("type")) {
      ctx.fillStyle = "rgba(255, 255, 255, 0.2)";
      ctx.beginPath();
      ctx.arc(b.x, b.y, R, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
    }
  });
}

// Update balls
function updateBalls() {
  var new_balls = [];
  Array.prototype.forEach.call(balls, function(b) {
    b.x += b.vx;
    b.y += b.vy;

    if (b.x > -50 && b.x < can_w + 50 && b.y > -50 && b.y < can_h + 50) {
      new_balls.push(b);
    }

    // alpha change
    b.phase += alpha_f;
    b.alpha = Math.abs(Math.cos(b.phase));
    // console.log(b.alpha);
  });

  balls = new_balls.slice(0);
}
// add balls if there a little balls
function addBallIfy() {
  if (balls.length < 20) {
    balls.push(getRandomBall());
  }
}

// Render
function render() {
  ctx.clearRect(0, 0, can_w, can_h);

  renderBalls();

  updateBalls();

  addBallIfy();

  window.requestAnimationFrame(render);
}

// Init Balls
function initBalls(num) {
  for (var i = 1; i <= num; i++) {
    balls.push({
      x: randomSidePos(can_w),
      y: randomSidePos(can_h),
      vx: getRandomSpeed("top")[0],
      vy: getRandomSpeed("top")[1],
      r: R,
      alpha: 1,
      phase: randomNumFrom(0, 10)
    });
  }
}
// Init Canvas
function initCanvas() {
  canvas.setAttribute("width", window.innerWidth);
  canvas.setAttribute("height", window.innerHeight);

  can_w = parseInt(canvas.getAttribute("width"));
  can_h = parseInt(canvas.getAttribute("height"));
}
window.addEventListener("resize", function(e) {
  console.log("Window Resize...");
  initCanvas();
});

function goMovie() {
  initCanvas();
  initBalls(30);
  window.requestAnimationFrame(render);
}
goMovie();
